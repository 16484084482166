#contactPage{
  min-height: calc(100vh - 4rem);
  width: 100vw;
  max-width: 60rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contactPageTitle{
  font-size: 3rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.clientDesc{
  font-weight: 300;
  font-size: 1rem;
  text-align: center;
  padding-bottom: 0.5rem;
}
.clientImgs{
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;
}

.clientImg{
  object-fit:contain;
  width: 400px;
  height: 200px;
  min-width: 9rem;
  margin: auto;
  padding: 0.25rem;
}
#clients{
  padding: 2rem; 
}
.contactDesc{
  text-align: center;
  padding: 1rem;
  font-size: medium;
  font-weight: 300;
}
.contactForm{
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90vw;
  max-width: 60rem;
}
.name, .email , .msg , .number{
  font-size: medium;
  width: 100%;
  max-width: 40rem;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  color:white;
  border: none;
  border-radius: 0.5rem;
  background: rgb(40,40,40);
}
.submitBtn{
  background: white;
  border: none;
  border-radius: 0.5rem;
  margin: 2rem;
  padding: 0.75rem 4.5rem;
}
.links{
  display: flex;
  flex-wrap: wrap;
}
.link{
  object-fit: cover;
  height: 3rem;
  width: 3rem;
  margin: 0 0.75rem;
}