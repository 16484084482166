#intro{
    height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 115rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    display: flex;
}

 .bg{
    padding-top: 7.5rem;
    position: absolute;
    padding-right: 2rem;
    top: -4rem;
    right: 9rem;
    z-index: -1;
    object-fit: cover;
    justify-content: center;
    margin-bottom:15rem;
    height: 650px;
    width: 450px;
}  
.bg1{
    margin-right: 27.5rem;
    padding-top: 7.5rem;
    position: absolute;
    padding-right: 2rem;
    top: -4rem;
    right: 7.5rem;
    z-index: -1;
    object-fit: cover;
    justify-content: center;
    height: 650px;
    width: 450px;
}   
.bg2{
    margin-right:53.5rem;
    padding-top: 7.5rem;
    position: absolute;
    padding-right: 2rem;
    top: -4rem;
    right: 7.5rem;
    z-index: -1;
    object-fit: cover;
    justify-content: center;
    height: 650px;
    width: 450px;
}   
.introContent {
    height: 100vh;
    width: 100vw;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.hello{
    font-size: 7.5rem;
    font-weight: 100;
}
.introText{
    color:aqua;
}
.made{
    color: white;
}
.intropara{
    font-family: sans-serif;
    font-size: medium;
    font-weight: 100;
    color:white;
}
.btn {
    background:white;
    margin: 1rem 0;
    padding: 0.75rem 1.5rem;
    border: none;
    display: flex;
    align-items: center;
    border-radius: 2rem;
}
 .btnImg {
    object-fit: cover;
   margin: 0 0.25rem;
    height: 1rem;
 }

 @media screen and (max-width:720px){
    .introContent{
        font-size: 10vw;
        height: 50vh;
        width: 50vw;
    }
    .hello{
        font-size: 5rem;
    }
 }
 @media screen and (max-width: 720px) {
    /* Adjust background images for mobile */
    .bg1,.bg2{
        display: none;
    }
    
  
    /* Scale down font sizes for mobile */
    .introContent {
      font-size: 10vw;
      height: 50vh;
      width: 50vw;
    }
  
    .hello {
      font-size: 5rem;
    }
  
    /* Adjust button styling */
    .btn {
      width: 100%;
      justify-content: center;
    }
  }