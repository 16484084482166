.navbar{
    background-color: black;
    height: 6rem;
    
    margin: 0 auto;
    padding: 1rem -2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
}

 .logo{
    object-fit:contain;
    display:flexbox;
    padding: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    height: 130px;
    width: 175px;
 }

 .desktopmenuitem{
  color:white;
    margin: 1rem;
    cursor: pointer;
 }

 .desktopmenuitem:hover {
   color: aqua;
   padding-bottom: 0.5rem;
   border-bottom: 3px solid aqua;

 }
.listitem{
  color:white;
    margin: 1rem;
    cursor: pointer;
}
 .desktopMenuBtn{
   background: white;
   color:black;
   border: none;
   display: flex;
   align-items:center;
   justify-content:space-evenly;
   border-radius: 2rem;
   padding: 0 1.25rem;
 }
 .destopMenuImg{
   object-fit: cover;
   height: 1.25rem;
   width: 1.25rem;
   margin: 1rem;
 }
 .active{
  color:aqua;
  border-bottom: 3px solid aqua;
  padding-bottom: 0.5rem;
 }
 .mobmenu{
  object-fit: cover;
  height: 1.25rem;
  width: 1.25rem;
  margin: 1rem;
 }
 .navmenu{
  position:absolute;
  top:4rem;
  right: 2rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  height: fit-content;
  min-width: 15rem;
  background-color:black; 
  border-radius: 1rem;
 }
 listitem{
  padding: 0.5rem 3rem;
  margin: 0.25rem;
  background-color: black;
 }
 .mobmenu{
  display: none;
  object-fit: cover;
  height: 2rem;
 }
 @media screen and (max-width:720px)  {
  .navbar{
    width: auto;
  }

    .mobmenu{
      display:flex;
    }
    .desktopmenu{
      display: none;
    }
    .desktopMenuBtn{
      display: none;
    }
 }